import React from 'react'
import Layout from '../components/layout'
import ContactStyles from '../components/visit.module.css'
import Image from 'gatsby-image'
import { graphql } from 'gatsby'
import MailChimpForm from '../components/MailChimpSubscribe'
import PortableText from '@sanity/block-content-to-react'


export const visitpageData = graphql `
query MyVisitQuery {
  sanityVisit {
    _rawAddress
    contact
    email
    id
    map {
      asset {
        fluid {
          ...GatsbySanityImageFluid
        }
      }
    }
  }
}
`
const Visit = ({ data }) =>{
    const address = data.sanityVisit._rawAddress
    return(
        <div className="visit-page">
        <Layout>
        <div className= "headerSpace"></div>
        <div>
            <div className={ContactStyles.visitContact}>
            
                  <PortableText
                    blocks = { address } 
                  />
                  <div className={ContactStyles.contact}>
                    <MailChimpForm />
                    <p>{data.sanityVisit.contact }<br></br>
                    <a href="mailto:anabelle.lacroix@gmail.com">{data.sanityVisit.email}</a>
                </p>
                </div>
            </div>

            <div className={ContactStyles.visitContact}>
                <Image fluid = { data.sanityVisit.map.asset.fluid} className= {ContactStyles.map} />  
                
            </div>
          </div>
        </Layout>
    </div>
    )
}

export default Visit
import addToMailchimp from "gatsby-plugin-mailchimp"
import React from "react"

const initialState = {
  email: "", 
  result: null,
  msg: "",
  error: ""
}

export default class MailChimpSubscribe extends React.Component {
    constructor() {
      super()
      this.state = initialState
    }

    validate = () => {
      let error = ""
      if(this.state.msg.includes('subscribed')){
        error = "You have already subscribed"
        this.setState({error: error})
      }
      if(error){
        return false
      }
      return true
    }

    _handleSubmit = async e => {
      e.preventDefault()
      const result = await addToMailchimp(this.state.email)
      //console.log(result)
      //console.log(result.msg)
      this.setState({result: result})
      const isValid = this.validate()
      if(isValid){   
        // when NOT valid ! show this >>>
        console.log(this.state)
        const errorMSG = 'You are already subscribed :–]'
        this.setState({msg: errorMSG})
      }else {
        this.setState({msg: result.msg})
      }
    }

    handleChange = event => {
      event.preventDefault()
      this.setState({ email: event.target.value })
    }

render() {

    return(
      <form onSubmit={this._handleSubmit}>
        <label>
            Newsletter <br></br>
          <input type="text" value={this.state.email} placeholder="subscribe with email" onChange={this.handleChange} />
        </label> <br /><br />     
        <input type="submit" value="Send/Envoyer"/> <br /><br />     
        <p>{ this.state.msg }</p>
      </form>
    )
  }
}